import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"

const NotFoundPageRoot = ({ pageContext }) => {
  //console.log("pageContext 404 page=", pageContext)
  pageContext.breadcrumb={location:"/404/"}
  return (
    <Layout seotitle="404: Not found"
      pageContext={pageContext}>
      <h1>Oops this page has disappeared</h1>
      <p>We recently made our site multi-languages, try to find your content in one of the languages below.</p>
      <div><Link to="/fr/">Lien vers la page d'Accueil</Link></div>
      <div><Link to="/en/">Link to English Home page</Link></div>
      <div><Link to="/nl/">Link to Dutch Home page</Link></div>
    </Layout>
  )
}

export default NotFoundPageRoot
